<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Stack border="b">
					<Section>
						<Heading size="1" uppercase>Contact</Heading>
					</Section>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12 6@md">
				<Stack direction="row column@md" align="left" width="100%">
					<Section size="large">
						<Stack width="100%" direction="column" align="left" space="1">
							<Heading size="2">Get in touch</Heading>
							<Paragraph>I would love to hear from you! Send your message here and I will get back to you as soon as possible.</Paragraph>
						</Stack>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 6@md">
				<Stack direction="column" align="left" width="100%" justify="spaceBetween" height="100%" border="t l@md">
					<Form>
						<FormContainer size="12">
							<Input label="NAME" id="name" @input="nameValue" :error="requiredError" />
						</FormContainer>
						<FormContainer size="12">
							<Input label="EMAIL" id="email" @input="emailValue" :error="requiredError" />
						</FormContainer>
						<FormContainer size="12">
							<Input label="SUBJECT" id="subject" @input="subjectValue" />
						</FormContainer>
						<FormContainer size="12">
							<Textarea label="MESSAGE" id="message" @textarea="messageValue" :error="requiredError" />
						</FormContainer>
					</Form>
					<Stack width="100%" justify="center">
						<Paragraph><span v-if="requiredError">Please add your name, your email and a message.</span></Paragraph>
					</Stack>
					<Button :label="errorMessage" v-if="errorMessage" color="danger" full size="large"></Button>
					<Button :label="successMessage" v-if="successMessage" color="secondary" full size="large"></Button>
					<Button label="SEND MESSAGE" v-if="!successMessage && !errorMessage" color="tertiary" @click.native="formValidation()" full size="large"></Button>
				</Stack>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/annarobbins/template/Page'; 
import axios from 'axios'
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String,
	},
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
			requiredError: false,
      successMessage: '',
      errorMessage: '',
		}
  },
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
  methods: {
		formValidation: function(){
			let error = false;
			if(document.getElementById('name').value === ''){
				this.runError();
				return error = true;
			}
			if(document.getElementById('email').value === ''){
				this.runError();
				return error = true;
			}
			if(document.getElementById('message').value === ''){
				this.runError();
				return error = true;
			}
			if (error === false){
				this.createContact();
				this.requiredError = false;
			}
		},
    createContact: function(){
			let app = this;
			let formData = new FormData();
			formData.append('name', this.name)
			formData.append('email', this.email)
			formData.append('subject', this.subject)
			formData.append('message', this.message)

			var contact = {};
			formData.forEach(function(value, key){
					contact[key] = value;
			});

			axios({
					method: 'post',
					url: './src/api/contacts.php',
					data: formData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
			})
			.then(function () {
				app.runSuccess();
			})
		},
    runSuccess: function() {
      this.successMessage = 'MESSAGE SENT!';
			setTimeout(() =>{ 
				this.successMessage = '';
			}, 10000);
    },
		runError: function(){
			this.requiredError = true;
			this.errorMessage = 'MESSAGE ERROR!';
			setTimeout(() =>{ 
				this.errorMessage = '';
			}, 10000);
		},
    nameValue: function(params) {
      this.name = params;
    },
    emailValue: function(params) {
      this.email = params;
    },
    subjectValue: function(params) {
      this.subject = params;
    },
    messageValue: function(params) {
      this.message = params;
    },
  },
}

</script>

<style lang="scss">
</style>
